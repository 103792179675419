import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { device } from "../utils/mixins"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageTopFullSizeImgCont,
  SectionContentContainer,
} from "../components/imageComponents"
import {
  PageSectionTitle,
  I2BronzeText,
  BodyText,
  BodyLink,
  BodyBold,
} from "../components/textComponents"

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

function ContactUsReceived() {
  var data = useStaticQuery(graphql`
    query {
      contactReceivedTopImg: file(
        relativePath: { eq: "i2OCompressedNaturalGasEnergyOptimization.jpeg" }
      ) {
        ...fluidImageSVG
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Message Received" />
      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.contactReceivedTopImg.sharp.fluid}
          alt="i2 Optimization - Compressed Natural Gas Energy Industry Optimization"
        />
        <PageSectionTitle>
          Message <I2BronzeText>Received</I2BronzeText>
        </PageSectionTitle>
      </PageTopFullSizeImgCont>
      <SectionContentContainer>
        <BodyText>
          We've got your message! We'll get back to you as soon as we have a
          spare moment.
        </BodyText>
        <BodyText>Feel free to check out the rest of our site:</BodyText>
        <BodyText>
          <BodyBold>
            To learn about cutting-edge technology that could transform your
            operations, check out our{" "}
            <BodyLink to="/case-studies/">Case Studies page.</BodyLink>
          </BodyBold>
        </BodyText>
        <BodyText>
          <BodyBold>
            To get a little bit more insight into our background, visit our{" "}
            <BodyLink to="/industry-experience/">
              Industry Experience page.
            </BodyLink>
          </BodyBold>
        </BodyText>
        <BodyText>
          <BodyBold>
            Have a question about our process, or optimization in general? Check
            out the <BodyLink to="/faqs/">FAQs page.</BodyLink>
          </BodyBold>
        </BodyText>
      </SectionContentContainer>
    </Layout>
  )
}

export default ContactUsReceived
